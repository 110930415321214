
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {users, findUserByName, getNotes, getUsersToSelectFrom} from "./Common";
import {v4 as uuidv4} from "uuid";
import {db} from "./dbRef";
import "./DetailExtras.css"
import {arrayUnion, doc, updateDoc, setDoc, serverTimestamp, arrayRemove} from "firebase/firestore";
import {Card, Row, Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";


function DetailExtras(props) {
    const [showInput,setShowInput] = useState(false);
    const[user,setUser] = useState('');
    const[notes,setNotes] = useState(null);
    //this state variable is a hacky workaround to avoid using forceUpdate or create other unnecessary state variables or watch database just to get a refresh
    //true or false have no intrinsic meaning, but the toggle of the state between the two will trigger a refresh
    const[likes,setLikes] = useState(null);
    const [dislikes, setDislikes] = useState(null);
    const recipe = props.recipe;

    useEffect(() => {
        setLikes(recipe.wouldMake);
        setDislikes(recipe.wouldNotMake);
        const fetchNotes = async () => {
            const noteList = await getNotes(recipe.recipeId,"Recipe");
            setNotes(noteList);
        };
        fetchNotes()
            .catch(console.error);
    }, []);

    // useEffect(() => {
    //     const q = query(collection(db,"notes"),orderBy('timestamp','desc'),where('recipeId',"==",recipe.recipeId));
    //     const unsubscribe = onSnapshot(q, (querySnapshot => {
    //         if (querySnapshot.empty) {
    //             setNotes([]);
    //             console.log("hit db in getting notes and got no notes");
    //         } else {
    //             console.log("hit db in getting notes and found notes");
    //             setNotes(querySnapshot.docs.map(doc => doc.data()));
    //         }
    //     }, error => {
    //         console.log("hit error getting notes from db", error);
    //         console.error(`Error loading notees: ${error}`);
    //     }));
    //
    //     return () => {
    //         unsubscribe()
    //     }
    // },[]);


    async function addLike(user) {
        console.log("this person disliked the recipe",user);
        console.log("recipe id in dislike is ",recipe.recipeId);
        if (user) updateDoc(doc(db,"recipes",recipe.recipeId),{wouldMake: arrayUnion(user)})
            .then(() => console.log("added like successfully to recipe"))
            .then(()=> console.log("addedLike successfully to user in db"))
            // TODO below is bad form and should set listeners on database to update recipe in state variable when have time
            .then(()=> setLikes((prevLikes)=>([...prevLikes,user])))
            .catch((error)=>console.log("There was an error in addLike in detailextras",error))
        if (user) {
            const fullUser = await findUserByName(user);
            await updateDoc(doc(db,"users",fullUser.userId),{userLikes: arrayUnion(recipe.recipeId)})
        }
    }

    async function addDislike(user) {
        console.log("this person disliked the recipe",user);
        console.log("recipe id in dislike is ",recipe.recipeId);
        if (user) updateDoc(doc(db,"recipes",recipe.recipeId),{wouldNotMake: arrayUnion(user)})
            .then(() => console.log("added dislike successfully to db"))
            // TODO below is bad form and should set listeners on database to update recipe in state variable when have time
            .then(()=> setDislikes((prevDislikes)=>([...prevDislikes,user])))
            .catch((error)=>console.log("There was an error in addDislike in Detailextras",error));
        if (user) {
            const fullUser = await findUserByName(user);
            await updateDoc(doc(db,"users",fullUser.userId),{userDislikes: arrayUnion(recipe.recipeId)})
        }
    }

    // this function adds recipe to users to try list and also
    // adds to recipe wanttomake list
    // if recipe has user on want to make list but not on wouldmake list it won't show up on the main feed'
    async function addToMake(user) {
        console.log("this person wants to make the recipe",user);
        console.log("recipe id in tomake is ",recipe.recipeId);
        //update recipes collection
        if (user) updateDoc(doc(db,"recipes",recipe.recipeId),{wantsToMake: arrayUnion(user)})
            .then(() => console.log("added to make successfully to db"))
            .catch((error)=>console.log("There was an error in addToMake in Detailextras",error));
        //update user collection
        if (user) {
            const fullUser = await findUserByName(user);
            await updateDoc(doc(db,"users",fullUser.userId),{userToTry: arrayUnion(recipe.recipeId)})
        }
    }

    async function moveToMadeLiked(user) {
        //update recipes collection
        if (user) updateDoc(doc(db,"recipes",recipe.recipeId),{wouldMake: arrayUnion(user),wantsToMake: arrayRemove(user)})
            .catch((error)=>console.log("There was an error movetomadelike in Detailextras",error));
        //update user collection
        if (user) {
            const fullUser = await findUserByName(user);
            await updateDoc(doc(db,"users",fullUser.userId),{userToTry: arrayRemove(recipe.recipeId), userLikes: arrayUnion(recipe.recipeId)})
        }
        setLikes((prevLikes)=>([...prevLikes,user]));

    }

    async function moveToMadeNoLike(user) {
        //update recipes collection
        if (user) updateDoc(doc(db,"recipes",recipe.recipeId),{wouldNotMake: arrayUnion(user),wantsToMake: arrayRemove(user)})
            .catch((error)=>console.log("There was an error in movetomadenolike in Detailextras",error));
        //update user collection
        if (user) {
            const fullUser = await findUserByName(user);
            await updateDoc(doc(db,"users",fullUser.userId),{userToTry: arrayRemove(recipe.recipeId),userDislikes: arrayUnion(recipe.recipeId)})
        }
        setDislikes((prevDislikes)=> ([...prevDislikes,user]));
    }

    async function saveNote() {
        console.log("got in saved note");
        const noteId = uuidv4();
        const commentTextBox = document.getElementById("recipe-comment");
        const submitComment = commentTextBox.value;

        const fullUser = await findUserByName(user);
        console.log("got past user as",user);
        console.log('props in saveNote recipe value',recipe);
        console.log('props recipe name in saveNote',recipe.name);
        const stateNote = {
            noteId: noteId,
            content: submitComment,
            recipeName: recipe.name,
            recipeId: recipe.recipeId,
            userId: fullUser.userId ? fullUser.userId : null,
            userName: user,
        };
        const newNote = {
            noteId: noteId,
            content: submitComment,
            recipeName: recipe.name,
            recipeId: recipe.recipeId,
            userId: fullUser.userId ? fullUser.userId : null,
            userName: user,
            timestamp:serverTimestamp()
        };

        await setDoc(doc(db,"users",fullUser.userId,"userNotes",noteId),newNote);
        await setDoc(doc(db,"notes",noteId),newNote);
        // should set listeners on database to update recipe in state variable
        // but since will rarely get comments and won't have multiple users on at once
        // going to keep it more simple by doing the below
        setNotes((prevNotes) => ([stateNote,...prevNotes]));
        // setNotes(notes.push(newNote));
        console.log("got past note add");
        console.log("new notes are", notes);
        setShowInput(false);
    }


    return(
        <div className="MainDiv">
            <br />
            <div className="KeeperDiv">
                {/*shouldn't need this anymore now have other buttons*/}
                {/*<label>*/}
                {/*    Keeper?*/}
                {/*    <select value={user} onChange={(event)=> addLike(event.target.value)}>*/}
                {/*        <option value="">Choose</option>*/}
                {/*        {getUsersToSelectFrom()}*/}
                {/*    </select>*/}
                {/*</label>*/}
                {/*<label>*/}
                {/*    Not a Keeper?*/}
                {/*    <select value={user} onChange={(event)=> addDislike(event.target.value)}>*/}
                {/*        <option value="">Choose</option>*/}
                {/*        {getUsersToSelectFrom()}*/}
                {/*    </select>*/}
                {/*</label>*/}
                <label style={{marginBottom: 0}}>
                    Put on 'To Make' List
                    <select onChange={(event)=> addToMake(event.target.value)}>
                        <option value="">Choose</option>
                        {getUsersToSelectFrom()}
                    </select>
                </label>
                <label style={{marginBottom: 0}}>
                    Move to 'Made' Likes List
                    <select onChange={(event)=> moveToMadeLiked(event.target.value)}>
                        <option value="">Choose</option>
                        {getUsersToSelectFrom()}
                    </select>
                </label>
                <label style={{marginBottom: 0}}>
                    Move to 'Made' Didn't Like List
                    <select onChange={(event)=> moveToMadeNoLike(event.target.value)}>
                        <option value="">Choose</option>
                        {getUsersToSelectFrom()}
                    </select>
                </label>
            </div>

            <br />
            {(likes) && <p style={{marginBottom: 0}}>Would Make Again: {likes.join(" ")}</p>}
            {(dislikes) && <p>Probably Not: {dislikes.join(" ")}</p>}

            {!showInput && <Button size="lg"
                                   variant="light"
                                   onClick={() => setShowInput(true)}>
                Add Note
            </Button>}
            {/*<Button variant="light" size="lg">*/}
            {/*    Block level button*/}
            {/*</Button>*/}
            {showInput && <div className="NoteAddDiv">
                <h3>Enter your note</h3>
                <textarea id="recipe-comment" placeholder={"Type note here"}/>
                <label>
                    Who is submitting?
                    <select value={user} onChange={(event)=> setUser(event.target.value)}>
                        <option value="">Choose</option>
                        {getUsersToSelectFrom()}
                    </select>
                </label>

                <button type="button"
                        className="primary-button"
                        onClick={()=>saveNote(props)}>
                    Submit
                </button>
                <button type="button"
                        className="primary-button"
                        onClick={()=>setShowInput(false)}>
                    Back
                </button>
            </div>}

            <div className="CommentDiv">
                <h3>Comments</h3>
                <Col>
                    {/*need to change so card vertical fits note*/}
                    {notes && notes.map(note =>
                        <Card key={note.noteId} className="GridCard">
                            <h6 class="text-center">{note.content}</h6>
                            <h6 style={{ marginBottom: 0 }}>Submitted by: {note.userName}</h6>
                            {note.timestamp && <i>{note.timestamp.toDate().toString().slice(0,25)}</i>}
                        </Card>
                    )}
                </Col>
            </div>
            {/*{props.note && <p> {props.note}</p>}*/}
            {/*{!props.note && <p> No one has left a note </p>}*/}
        </div>
    )
}

DetailExtras.propTypes = {
    recipe:PropTypes.object.isRequired
};

export default DetailExtras;