import React, {useEffect, useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import {collection, query, where, getDocs, updateDoc, arrayUnion, doc, setDoc, serverTimestamp} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import './Common.css';
import './AddRecipe.css';
import {getUsersToSelectFrom} from "./Common";
// import ErrorMessage from "./ErrorMessage";
// import ModalDialog from "./ModalDialog";
// import {logPageView} from "./analytics";
import {mealProperties, mealCategory, users, findUserByName} from "./Common";
import {db, fbStorage} from "./dbRef";
import Alert from "bootstrap/js/src/alert";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";


// class WineAlreadyExistsError extends Error {
//     constructor() {
//         super('This wine already exists in the database.');
//     }
// }

function AddRecipe() {

    const [category, setCategory] = useState(null);
    const [properties, setProperties] = useState([]);
    const [user, setUser] = useState(users[0]);
    const [isCheatDay, setIsCheatDay] = useState(false);
    const [isMade, setIsMade] = useState(true);
    const [selectedFile,setSelectedFile] = useState();
    const [selectedPictureFile,setSelectedPictureFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [isPictureSelected, setIsPictureSelected] = useState(false);
    const [insufficientInfoError, setInsufficientInfoError] = useState(false);
    // const [
    //     errorWineAlreadyExists,
    //     setErrorWineAlreadyExists
    // ] = useState(false);
    // const [errorSavingWine, setErrorSavingWine] = useState(false);
    // // TODO: Toast isn't working
    const [showAddedToast, setShowAddedToast] = useState(false);
    let recipeData;
    let navigate = useNavigate();

    // useEffect(() => {
    //     logPageView("add_wine");
    // })

    function openHomePage() {
        navigate("/");
            // window.location.href = `/`;
    }

    const uploadChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const uploadPictureChangeHandler = (event) => {
        setSelectedPictureFile(event.target.files[0]);
        setIsPictureSelected(true);
    };

    function getRecipeData() {
        const recipeId = uuidv4();
        const nameTextBox = document.getElementById("recipe-name");
        const weblinkTextBox = document.getElementById("weblink");
        const piclinkTextBox = document.getElementById("piclink");
        const sourceTextBox = document.getElementById("recipe-source");

        const submitName = nameTextBox.value;
        const submitWebLink = weblinkTextBox.value ? weblinkTextBox.value : null;
        const submitPicLink = piclinkTextBox.value ? piclinkTextBox.value : null;
        const submitSource = sourceTextBox.value ? sourceTextBox.value : null;

        const wouldMake = isMade ? [user] : [];
        const wantsToMake = !isMade ? [user] : [];

//took out requiring weblink and source below in case just want to do pdf of own recipe
        if (!(submitName && category)) {
            setInsufficientInfoError(true);
            return;
        }


        //TODO will also need to save to users, etc.
        //if submit and say have made it, automatically give it a thumbs up from that user
        recipeData = {
            key: recipeId,
            recipeId: recipeId,
            submittedBy: user,
            weblink: submitWebLink,
            name: submitName,
            imagelink: submitPicLink,
            fullRecipeImage: null,
            recipeSource: submitSource,
            mealCategory: category,
            recipeAttributes: properties,
            dateAdded: serverTimestamp(),
            wantsToMake: wantsToMake,
            wouldMake: wouldMake,
            wouldNotMake: [],
            cheatDay: isCheatDay,
        };
        return recipeId;
    }

    async function addRecipeToUserLikesorMakes(recipeId) {
        //WARNING this only works if no duplicate user names so dangerous if expand outside family
        const fullUser = await findUserByName(user);
        if (isMade) {
            if (fullUser) await updateDoc(doc(db, "users", fullUser.userId), {userLikes: arrayUnion(recipeId)});
        } else {
            if (fullUser) await updateDoc(doc(db, "users", fullUser.userId), {userToTry: arrayUnion(recipeId)});
        }
    }

    async function handleDownloadSubmission(recipeId) {
        console.log("I handled a submission!");
        if (selectedFile) {
            const publicRef = ref (fbStorage,'public');
            const imageRef = ref(publicRef,selectedFile.name);

            await uploadBytes(imageRef, selectedFile)
                .then((snapshot) => {
                    console.log("Uploaded a blob or file with name",selectedFile.name,"with snapshot",snapshot);
                });

            getDownloadURL(imageRef)
                .then ((url) => {
                    console.log("Url returned is: ",url);
                    updateDoc(doc(db,"recipes",recipeId), {
                        fullRecipeImage: url
                    });
                })
                .catch((error) => console.log("problem getting download url",error))
                .finally();
        }
    }

    async function handlePictureDownloadSubmission(recipeId) {
        console.log("I handled a submission!");
        if (selectedPictureFile) {
            const publicRef = ref (fbStorage,'public');
            const imageRef = ref(publicRef,selectedPictureFile.name);

            await uploadBytes(imageRef, selectedPictureFile)
                .then((snapshot) => {
                    console.log("Uploaded a blob or file with name",selectedPictureFile.name,"with snapshot",snapshot);
                });

            getDownloadURL(imageRef)
                .then ((url) => {
                    console.log("Url returned is: ",url);
                    //by having it return this it should wait until updateDoc is completed and promise resolved before moving on to finally
                    //if don't have return statement and just call updateDoc  the anonymous function will return void and
                    //finally will be called as soon as updateDoc is initiated
                    return updateDoc(doc(db,"recipes",recipeId), {
                        imagelink: url
                    });
                })
                .catch((error) => console.log("problem getting download url",error))
                .finally();
        }
    }





    //add recipe to recipe collection and if user has made that recipe add the recipe to that users likes
    async function submitRecipe() {
        let recipeId = getRecipeData();
        // await addDoc(collection(dbRef,"recipes"));
        if (recipeData) {
            setDoc(doc(db, "recipes", recipeId), recipeData)
                .then (() => handleDownloadSubmission(recipeId))
                .then (() => handlePictureDownloadSubmission(recipeId))
                .then (() => (addRecipeToUserLikesorMakes(recipeId))
                .catch((error)=> console.log("Error in submitting recipe",error))
                .finally(openHomePage))
        }
    }

    function propertiesOnClick(buttonProperty) {
        let newArray = [...properties];
        if (properties.includes(buttonProperty)) {
            newArray = newArray.filter(property => property != buttonProperty);
            setProperties(newArray);
        } else {
            newArray.push(buttonProperty);
            setProperties(newArray);
        }
    }

    function getPropertiesToSelectFrom() {
        let propertyButtons = [];
        mealProperties.forEach(buttonProperty => propertyButtons.push(
            <button type="button"
                    key={buttonProperty}
                    className={`btn ${properties.includes(buttonProperty) ? 'btn-light' : 'btn-secondary'}`}
                    onClick={() => propertiesOnClick(buttonProperty)}>
                {buttonProperty}
            </button>
        ));
        return propertyButtons;
    }

    function getCategoriesToSelectFrom() {
        let categoryButtons = [];
        mealCategory.forEach(buttonCategory => categoryButtons.push(
            <button type="button"
                    key={buttonCategory}
                    className={`btn ${category === buttonCategory ? 'btn-light' : 'btn-secondary'}`}
                    onClick={() => setCategory(buttonCategory)}>
                {buttonCategory}
            </button>
        ));
        return categoryButtons;
    }

    return (
        <div className="AddRecipe">
            <h5>Add New Recipe</h5>
            <div className="form">
                <h6>
                    <i>Must enter at least recipe name and category before submitting</i>
                </h6>
                <br/>
                <div>
                    <div className="row">
                        <div className="label">Name of Recipe</div>
                        <input id="recipe-name"
                               type="string"
                            //onChange={input => nameAdded(input)}
                               aria-describedby="recipe-name"
                               placeholder="Enter name of recipe"/>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="label">Link to Recipe</div>
                        <input id="weblink"
                               type="string"
                            //onChange={input => numberAdded(input)}
                               aria-describedby="weblink"
                               placeholder="Enter full web address of recipe"/>
                    </div>
                    <br/><br/>
                    <div className="row">
                        <div className="label">Enter Link to Main Picture of Recipe</div>
                        <input id="piclink"
                               type="string"
                            //onChange={input => descAdded(input)}
                               aria-describedby="piclink"
                               placeholder="Copy and paste link for main picture on recipe page"/>
                    </div>
                    <h4>OR</h4>
                    <input type="file" name="file" onChange={uploadPictureChangeHandler} />
                    {isPictureSelected ? (
                        <div>
                            <p>Filename: {selectedPictureFile.name}</p>
                            <p>Filetype: {selectedPictureFile.type}</p>
                            <p>Size in bytes: {selectedPictureFile.size}</p>
                            <p>
                                lastModifiedDate:{' '}
                                {selectedPictureFile.lastModifiedDate.toLocaleDateString()}
                            </p>
                        </div>
                    ) : (
                        <p>Select picture from your computer in jpeg, gif, or png form</p>
                    )}
                    <br/>
                    <div>
                        <h6>Enter image of recipe instructions</h6>
                        <input type="file" name="file" onChange={uploadChangeHandler} />
                        {isSelected ? (
                            <div>
                                <p>Filename: {selectedFile.name}</p>
                                <p>Filetype: {selectedFile.type}</p>
                                <p>Size in bytes: {selectedFile.size}</p>
                                <p>
                                    lastModifiedDate:{' '}
                                    {selectedFile.lastModifiedDate.toLocaleDateString()}
                                </p>
                            </div>
                        ) : (
                            <p>Select a file with full recipe in pdf, jpeg, gif, or png form</p>
                        )}
                    </div>
                    <br/>
                    <div className="row">
                        <div className="label">Recipe Source</div>
                        <input id="recipe-source"
                               type="string"
                               aria-describedby="recipe-source"
                               placeholder="Enter name of blog or website recipe was taken from"/>
                    </div>
                    <br/><br/>
                    <div className="row">
                        <div className="label">What is the category of food?</div>
                    </div>
                    <div className="multi-select-row">
                        {getCategoriesToSelectFrom()}
                    </div>
                    <br/>
                    <div className="row">
                        <div className="label">Select all properties that apply</div>
                    </div>
                    <div className="multi-select-row">
                        {getPropertiesToSelectFrom()}
                    </div>
                    <br/>
                    <div className="row">
                        <div className="label">Select if this a recipe you've already made and know is good or one you'd like to make in the future</div>
                    </div>
                    <div className="multi-select-row">
                        <button type="button"
                                key='made'
                                className={`btn ${isMade ? 'btn-light' : 'btn-secondary'}`}
                                onClick={() => setIsMade(true)}>
                            Made
                        </button>
                        <button type="button"
                                key="notMade"
                                className={`btn ${!isMade ? 'btn-light' : 'btn-secondary'}`}
                                onClick={() => setIsMade(false)}>
                            Wanna Make
                        </button>
                    </div>
                    {/*<label>*/}
                    {/*    Have you made this recipe?*/}
                    {/*    <input*/}
                    {/*        name="made"*/}
                    {/*        type="checkbox"*/}
                    {/*        checked={isMade}*/}
                    {/*        onChange={() => setIsMade(!isMade)} />*/}
                    {/*</label>*/}
                    <br/>
                    <label>
                        Is it a 'cheat day' recipe?
                        <input
                            name="isCheat"
                            type="checkbox"
                            checked={isCheatDay}
                            onChange={() => setIsCheatDay(!isCheatDay)} />
                    </label>
                    <label>
                        Who is submitting?
                        <select value={user} onChange={(event)=> setUser(event.target.value)}>
                            {getUsersToSelectFrom()}
                        </select>
                    </label>

                </div>
                {/*{insufficientInfoError && (*/}
                {/*    <ErrorMessage message={*/}
                {/*        "Please fill out at least the name of the recipe, link to the recipe, "*/}
                {/*        + "source of the recipe(blog name, etc), and recipe category before submitting."*/}
                {/*    } />*/}
                {/*)}*/}
                {insufficientInfoError && (<div className="alert alert-danger" role="alert">
                    "Please fill out at least the name of the recipe, link to the recipe,
                    source of the recipe(blog name, etc), recipe category, and whether
                    you've made it before submitting.
                </div>)}
                <br/>
                <button type="button"
                        className="submit primary-button"
                        onClick={() => submitRecipe()}>
                    SUBMIT
                </button>
            </div>
        </div>
    )
}

export default AddRecipe;
