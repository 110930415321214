import {initializeApp} from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//     apiKey: process.env.development.REACT_APP_API_KEY,
//     authDomain: process.env.development.REACT_APP_AUTH_DOMAIN,
//     projectId: process.env.development.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.development.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.development.REACT_APP_MESSAGING_SENDER_ID,
//     appId: process.env.development.REACT_APP_APP_ID
// }

let firebaseConfig;
if (window.location.hostname === "www.thetayloredcook.com") {
    console.log("I'm using the production database!");
    firebaseConfig = {
        apiKey: "AIzaSyBAkK5U3LvKYNlP2dqubrHs3Y6egi1OvQo",
        authDomain: "the-taylored-cook.firebaseapp.com",
        projectId: "the-taylored-cook",
        storageBucket: "the-taylored-cook.appspot.com",
        messagingSenderId: "398412370353",
        appId: "1:398412370353:web:0995002415f22df6b06d90"
    }
} else {
    console.log("I'm using the development database!");
    firebaseConfig = {
        apiKey: "AIzaSyC1JGdxIvgJYj6IUOnWmtB9h6U_bz2fSUg",
        authDomain: "the-taylored-cook-dev.firebaseapp.com",
        projectId: "the-taylored-cook-dev",
        storageBucket: "the-taylored-cook-dev.appspot.com",
        messagingSenderId: "76207285765",
        appId: "1:76207285765:web:3706bcd62e6979d2cede6c"
    }
}


const app = initializeApp(firebaseConfig);

// if (process.env.REACT_APP_USE_ANALYTICS) {
//     const analytics = getAnalytics(app);
// }
// //since not exporting not sure if this is the right place to do analytics

export default app;