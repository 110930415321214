import React from "react";
import './Header.css';

function Header() {
    return (
        <div className="HeaderDiv">
            <h3>The Taylored Cook</h3>
            <h4>Recipes for Healthier Living</h4>
        </div>
    )
}
export default Header;

