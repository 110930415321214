import DetailExtras from "./DetailExtras";
import DetailRecipe from "./DetailRecipe";
import {useParams} from 'react-router-dom';
import './RecipeDetail.css'
import {useEffect, useLayoutEffect, useState} from "react";
import {db} from "./dbRef";
import {debounce} from "./Common";
import {doc, getDoc} from 'firebase/firestore';
import Tabs from "react-bootstrap/Tabs";
import Tab from "bootstrap/js/src/tab";

function RecipeDetail() {
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const {recipeId}  = useParams();
    console.log("My recipeId is",recipeId);
    const [recipe, setRecipe] = useState(null);
    const mql = window.matchMedia('(max-width: 800px)');
    console.log("Screen is small? ",mql.matches);
    console.log("user from session storage in detail is is ",sessionStorage.getItem('user'));

    useEffect(() => {
        const fetchRecipe = async () => {
            const docRef = doc(db,'recipes',recipeId);
            const docSnap = await getDoc(docRef);
            console.log("The db is: ",db);
            console.log("doc snap returned is ",docSnap.data());
            setRecipe(docSnap.data());
            console.log("State recipe is ", recipe);
        };

        fetchRecipe()
            .catch(console.error);
    }, []);

    useLayoutEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            console.log("dimensions are"+dimensions.height+" and "+dimensions.width);
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 50);

        window.addEventListener('resize', debouncedHandleResize);

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    });

    return(
        //issue is that doesn't seem to re-run media query if increase screen size - otherwise is working
        <div>
            {(dimensions.width<850) && <Tabs defaultActiveKey="recipe" id="recipe_detail_tab" className="TabContainer">
                <Tab eventKey="recipe" title="Recipe" className="TabRecipe">
                    {recipe && <DetailRecipe recipe={recipe}/>}
                </Tab>
                <Tab eventKey="notes" title="Notes" className="TabDetail">
                    {recipe && <DetailExtras recipe={recipe}/>}
                </Tab>
            </Tabs>};
            {(dimensions.width >= 850) && <div className = "DetailContainer">
                {/*{recipe && <div class="p-2 flex-fill bd-highlight">*/}
                {/*    <DetailRecipe fullRecipeImage={recipe.fullRecipeImage} name={recipe.name}/>*/}
                {/*</div>}*/}
                {recipe && <div className="DetailRecipe">
                    <DetailRecipe recipe={recipe}/>
                </div>}
                {/*<DetailExtras/>*/}
                {/*{recipe && <div class="p-2 flex-fill bd-highlight">*/}
                {/*    <DetailExtras recipe={recipe}/>*/}
                {/*    </div>}*/}
                {recipe && <div className="DetailExtras">
                    <DetailExtras recipe={recipe}/>
                </div>}
            </div>}
        </div>
    )


}

export default RecipeDetail;