import './Common.css';
import {allOption,users,mealCategory} from "./Common";
import './RecipeList.css';
import {collection, getDocs, query,orderBy} from "firebase/firestore";
import React, {useEffect,useState} from 'react';
import {db} from './dbRef.js';
import {Container, Col, Row, Card, Image, Button, Dropdown} from 'react-bootstrap';
import {Link} from "react-router-dom"
import { useNavigate } from "react-router-dom";
import genericPic from './generic_food.jpeg';
// import LinkOut from "./LinkOut";


function RecipeList() {

    const [recipes, setRecipes] = useState(null);
    //liked recipes defined as ones user liked but did not submit
    const [dislikedRecipes, setDislikedRecipes] = useState(null);
    const [likedRecipes, setLikedRecipes] = useState(null);
    const [filteredRecipes, setFilteredRecipes] = useState(null);
    const [toMakeRecipes, setToMakeRecipes] = useState(null);
    const [userFilter, setUserFilter] = useState(allOption);
    const [categoryFilter, setCategoryFilter] = useState(allOption);
    const [cheat, setCheat] = useState(false);

    let navigate = useNavigate();
    console.log("user from initial state  is ",userFilter);
    console.log("user from session storage is ",sessionStorage.getItem('user'));
    console.log("cheat from session storage is ",typeof sessionStorage.getItem('cheat'));

    // was used to create first users after importing users and createUser from Commoon
    // useEffect(() => {
    //         const db = dbRef();
    //         users.forEach(user => createUser(user,db))
    //     }, []);

    useEffect(() => {
        const fetchData = async () => {
            let q = query(collection(db,'recipes'), orderBy("dateAdded", "desc"));
            const querySnap = await getDocs(q);
            querySnap.forEach((doc) => {
                console.log(doc.data());
            });
            let recipeList = querySnap.docs.map(doc => doc.data());
            setRecipes(recipeList);
            setFilteredRecipes(recipeList);
            if (sessionStorage.getItem('user')) setUserFilter(sessionStorage.getItem('user'));
            if (sessionStorage.getItem('category')) setCategoryFilter(sessionStorage.getItem('category'));
            if (sessionStorage.getItem('cheat')) {
                if (sessionStorage.getItem('cheat') === 'true') {
                    setCheat(true);
                } else {
                    setCheat(false);
                }
            }
        };

        fetchData()
            .catch(console.error);

    }, []);

    useEffect(() => {
        if (recipes) {
            const starterList = [...recipes];
            if (userFilter == allOption) {
                if (categoryFilter == allOption) {
                    const filteredMadeRecipes = starterList.filter(recipe => {return recipe.wouldMake.length>0 && recipe.cheatDay === cheat});
                    setFilteredRecipes(filteredMadeRecipes);
                    setToMakeRecipes(null);
                    setLikedRecipes(null);
                    setDislikedRecipes(null);
                } else {
                    const submittedCategoryRecipes = starterList.filter(recipe => {return recipe.mealCategory === categoryFilter && recipe.cheatDay === cheat});
                    const submittedMadeCategoryRecipes = submittedCategoryRecipes.filter(recipe => {return recipe.wouldMake.length>0  && recipe.cheatDay === cheat});
                    setToMakeRecipes(null);
                    setLikedRecipes(null);
                    setDislikedRecipes(null);
                    setFilteredRecipes(submittedMadeCategoryRecipes);
                }
            } else {
                const submittedRecipes = starterList.filter(recipe => {return recipe.submittedBy === userFilter && recipe.wouldMake.includes(userFilter) && recipe.cheatDay === cheat});
                const filteredLikedRecipes = starterList.filter(recipe => {
                    return recipe.wouldMake.includes(userFilter) && recipe.submittedBy !== userFilter  && recipe.cheatDay === cheat});
                const wantToMakeRecipes = starterList.filter(recipe => {return recipe.wantsToMake.includes(userFilter) && recipe.cheatDay === cheat});
                const nonLikedRecipes = starterList.filter(recipe => {return recipe.wouldNotMake.includes(userFilter) && recipe.cheatDay === cheat});
                if (categoryFilter == allOption) {
                    setLikedRecipes(filteredLikedRecipes);
                    setFilteredRecipes(submittedRecipes);
                    setToMakeRecipes(wantToMakeRecipes);
                    setDislikedRecipes(nonLikedRecipes);
                } else {
                    const userAndCategorySubmittedRecipes = submittedRecipes.filter(recipe => recipe.mealCategory == categoryFilter);
                    const userAndCategoryLikedRecipes = filteredLikedRecipes.filter(recipe => recipe.mealCategory == categoryFilter);
                    const userAndCategoryToMakeRecipes = wantToMakeRecipes.filter(recipe => recipe.mealCategory == categoryFilter);
                    const userAndCategoryDislikedRecipes =  nonLikedRecipes.filter(recipe => recipe.mealCategory == categoryFilter);
                    setLikedRecipes(userAndCategoryLikedRecipes);
                    setFilteredRecipes(userAndCategorySubmittedRecipes);
                    setToMakeRecipes(userAndCategoryToMakeRecipes);
                    setDislikedRecipes(userAndCategoryDislikedRecipes);
                }
            }
        }
    },[userFilter, categoryFilter, recipes, cheat]);

    // useEffect(() => {
    //     if (userFilter!== allOption) {
    //         const fetchLikedData = async () => {
    //             const q = query(collection(db,'recipes'),where("wouldMake", "array-contains", userFilter));
    //             const querySnap = await getDocs(q);
    //             querySnap.forEach((doc) => {
    //                 console.log(doc.data());
    //             });
    //             let likedRecipeList = querySnap.docs.map(doc => doc.data());
    //             setLikedRecipes(likedRecipeList);
    //         };
    //
    //         fetchLikedData()
    //             .catch(console.error);
    //     }
    //
    // },[userFilter]);

    function goToAddRecipe() {
        navigate("/AddRecipe");
    }

    function getDropdownUsersToSelectFromWithAll() {
        let userChoices = [];
        userChoices.push( <Dropdown.Item as="button" onClick={() => handleUser(allOption)}>{allOption}</Dropdown.Item>);
        users.forEach(user => userChoices.push(
            <Dropdown.Item as="button" key={user} onClick={()=>handleUser(user)}>{user}</Dropdown.Item>
        ));
        return userChoices;
    }

    function getDropdownCategoryToSelectFromWithAll() {
        let categoryChoices = [];
        categoryChoices.push( <Dropdown.Item as="button" onClick={() => handleCategory(allOption)}>{allOption}</Dropdown.Item>);
        mealCategory.forEach(category => categoryChoices.push(
            <Dropdown.Item as="button" key={category} onClick={()=>handleCategory(category)}>{category}</Dropdown.Item>
        ));
        return categoryChoices;
    }

    function getCheatTitle() {
            if (cheat) return "Not So Healthy";
            return "Healthy";
    }

    function handleUser(userChoice) {
        console.log("handle user got called with user: ",userChoice);
        if (userChoice == allOption) {
            setUserFilter(allOption);
            sessionStorage.setItem('user',allOption);
        } else {
            setUserFilter(userChoice);
            sessionStorage.setItem('user',userChoice);
        }
    }

    function handleCategory(categoryChoice) {
        console.log("handle category got called with user: ",categoryChoice);
        if (categoryChoice == allOption) {
            sessionStorage.setItem('category',allOption);
            setCategoryFilter(allOption);
        } else {
            sessionStorage.setItem('category',categoryChoice);
            setCategoryFilter(categoryChoice);
        }
    }

    function handleCheat(cheatChoice) {
        console.log("handle cheat got called with user: ",cheatChoice);
        if (cheatChoice == true) {
            sessionStorage.setItem('cheat','true');
            setCheat(true);
        } else {
            sessionStorage.setItem('cheat','false');
            setCheat(false);
        }
    }

    function handleSearch(searchTerm) {
            const searchedRecipes = recipes.filter((recipe)=> recipe.name.toLowerCase().includes(searchTerm.toLowerCase()));
            setFilteredRecipes(searchedRecipes);

    }

    function goToRecipeDetail(recipe) {
            // console.log("go to recipe was called");
            navigate(`/${recipe.recipeId}`);
    }

    function getRecipeCards(toGetRecipeList) {
        return (toGetRecipeList.map(recipe =>
                <Card key={recipe.recipeId} className="GridCard">
                    <div className="TitleDiv">
                        <h6 className="text-center">{recipe.name}</h6>
                    </div>
                    {recipe.imagelink && <img src={recipe.imagelink} onClick={() => goToRecipeDetail(recipe)}/>}
                    {!recipe.imagelink && <img src={genericPic} onClick={() => goToRecipeDetail(recipe)}/>}
                    {recipe.recipeSource && <p style={{ marginBottom: 0}}>Source:{recipe.recipeSource}</p>}
                    {recipe.submittedBy && <i style={{fontSize:14}}>Submitted by {recipe.submittedBy}</i>}
                    {recipe.cheatDay && <p style = {{marginBottom: 0}}>*** CHEAT DAY ***</p>}
                    {recipe.recipeAttributes.length>0 && !recipe.cheatDay && <p style={{ marginBottom: 0}}>{recipe.recipeAttributes.map(attribute => attribute+" ")}</p>}
                </Card>)
        )
    }

    return (
        <div>
            <Container className="MainRecipeListContainer">
                <div className="TopRow">
                        <Dropdown>
                            <Dropdown.Toggle size='lg'
                                             variant="light"
                                             id="dropdown-user">
                                Show {userFilter} Recipes
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {getDropdownUsersToSelectFromWithAll()}
                            </Dropdown.Menu>
                        </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle size='lg'
                                         variant="light"
                                         id="dropdown-category">
                            Show {categoryFilter} Categories
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {getDropdownCategoryToSelectFromWithAll()}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle size='lg'
                                         variant="light"
                                         id="dropdown-category">
                            {getCheatTitle()}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item as="button" key="cheatNo" onClick={() => handleCheat(false)}>Show Healthy</Dropdown.Item>
                            <Dropdown.Item as="button" key="cheatYes" onClick={()=>handleCheat(true)}>Show Not So Healthy</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                        <div>
                            <Button size="lg"
                                    variant="light"
                                    onClick={() => goToAddRecipe()}>
                                Add New Recipe
                            </Button>
                        </div>
                        <div>
                            <Button size="lg"
                                    variant="light">
                                <input placeholder="Search by Recipe Name" onChange={(event) => handleSearch(event.target.value)} />
                            </Button>
                        </div>
                </div>

                <Row xs="1" sm="2" md="2" lg="3" xl="4" height="200" className="ListRow">
                    {filteredRecipes && getRecipeCards(filteredRecipes)}
                </Row>

                {(likedRecipes && likedRecipes.length > 0) &&
                <div>
                    <div className="DividerDiv">
                        <hr/>
                        <h3> Liked Recipes </h3>
                        <hr/>
                    </div>
                    <Row xs="1" sm="2" md="2" lg="3" xl="4" height="200" className="ListRow">
                        {getRecipeCards(likedRecipes)}
                    </Row>
                </div>}

                {toMakeRecipes && toMakeRecipes.length > 0 &&
                <div>
                    <div className="DividerDiv">
                        <hr/>
                        <h3> Future Recipes to Make </h3>
                        <hr/>
                    </div>
                    <Row xs="1" sm="2" md="2" lg="3" xl="4" height="200" className="ListRow">
                        {getRecipeCards(toMakeRecipes)}
                    </Row>
                </div>}

                {dislikedRecipes && dislikedRecipes.length > 0 &&
                <div>
                    <div className="DividerDiv">
                        <hr/>
                        <h3> Disliked Recipes </h3>
                        <hr/>
                    </div>
                    <Row xs="1" sm="2" md="2" lg="3" xl="4" height="200" className="ListRow">
                        {getRecipeCards(dislikedRecipes)}
                    </Row>
                </div>}
            </Container>
        </div>
    )
}

export default RecipeList