import PropTypes from "prop-types";
import LinkOut from "./LinkOut";
import {getDoc} from "firebase/firestore";
import genericPic from "./generic_food.jpeg";
import "./DetailRecipe.css";
import db from "./dbRef";

function DetailRecipe(props) {
    console.log("props recipe is",props.recipe);
    console.log("props recipe link is",props.recipe.weblink);
    console.log("props recipe fullimagelink is",props.recipe.fullRecipeImage);
    let recipeImage  = props.recipe.fullRecipeImage ? props.recipe.fullRecipeImage: genericPic;

    return(
        <div>
            {(!props.recipe.fullRecipeImage && props.recipe.weblink) && <LinkOut url={props.recipe.weblink} text="Go to Full Recipe"></LinkOut>}
            {(!props.recipe.fullRecipeImage && !props.recipe.weblink) && <h2>No image for or link to recipe</h2>}
            {/*<img src={picLink}/>;*/}
            {/*contentType an name are in metadata in snap retured from firebase storage*/}
            <br/>
            <iframe src={recipeImage} width="100%" height="800"/>
            {/*<object type="application/pdf"*/}
            {/*        data="https://firebasestorage.googleapis.com/v0/b/the-taylored-cook-dev.appspot.com/o/public%2FDell%20Monitor%20Receipt.pdf?alt=media&token=1721fe3c-0b6d-4d90-a9a5-28798cd3095c"*/}
            {/*        width="250"*/}
            {/*        height="200">*/}
            {/*</object>*/}
            {/*<img src="https://firebasestorage.googleapis.com/v0/b/the-taylored-cook-dev.appspot.com/o/public%2FDell%20Monitor%20Receipt.pdf?alt=media&token=1721fe3c-0b6d-4d90-a9a5-28798cd3095c" />*/}
            {/*<embed src="https://ide.geeksforgeeks.org"*/}
            {/*       width="400"*/}
            {/*       height="400" />*/}
            {/*<script s  rc={props.link}></script>*/}
        </div>
    )
}
DetailRecipe.propTypes = {
    recipe:PropTypes.object.isRequired
};
export default DetailRecipe;