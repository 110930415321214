import './App.css';
import React from 'react';
import RecipeList from "./RecipeList";
import RecipeDetail from "./RecipeDetail";
import Header from "./Header";
import AddRecipe from "./AddRecipe";
import {BrowserRouter, Routes, Route} from "react-router-dom";



function App() {
    return (
        <div>
            <Header/>
            <BrowserRouter>
                <Routes>
                    <Route path="/AddRecipe" element={<AddRecipe/>}/>
                    <Route path="/:recipeId" element={<RecipeDetail/>}/>
                    <Route path="/" element={<RecipeList/>}/>
                    {/*do I need to use the word exact bc am using some functionality of old version*/}
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App;
