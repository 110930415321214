import {getFirestore} from "firebase/firestore";
import firebaseApp from './firebaseApp';
import {getStorage, ref} from "firebase/storage";

// function dbRef() {
//     return getFirestore(firebaseApp);
// }

const db = getFirestore(firebaseApp);

const fbStorage = getStorage(firebaseApp);


// function storageRef() {
//     return getStorage(firebaseApp);
// }

export {db, fbStorage};
