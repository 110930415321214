import {collection, doc, getDocs, query, serverTimestamp, setDoc, where} from "firebase/firestore";
import {db} from "./dbRef";
import { v4 as uuidv4 } from 'uuid';
import React from "react";
import {Dropdown} from "react-bootstrap";

const mealCategory = ['Main Dish','Appetizer','Breakfast','Side Dish','Soup','Salad','Drink','Dessert','Bread/Pastry','Etcetera'];  //implement in drop down
const mealProperties = ['Instant Pot','Air Fryer','Vegetarian','Vegan','Low Carb','Quick'];  //impement to select all that apply
const users = ["Laura","Heather","Taylor","Charlene","Matt","Justin"]; //eventually populate from db?
const allOption = "All";

async function createUser(user, db) {
    const userId = uuidv4();
    const userData = {
        key: userId,
        userId: userId,
        userName: user,
        userLikes: [], //recipeIds of liked recipes
        userDislikes: [], //recipeIds of disliked recipes
        userToTry: [], //links or ids of recipes to try
    };

    await setDoc(doc(db, "users", userId), userData);
}

function getUsersToSelectFrom() {
    let userChoices = [];
    // setUser(users[0]);
    users.forEach(user => userChoices.push(
        <option value={user} key={user}>{user}</option>
    ));
    return userChoices;
}

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

async function findUserByName(userName) {
    let userFetched;
    const q = query(collection(db, "users"), where("userName", "==", userName));
    const querySnapshot = await(getDocs(q));
    querySnapshot.forEach(snap => console.log("snap data is",snap.data()));
    //not as good as true error checking, but for now...
    if (querySnapshot.docs.length == 1) {
        userFetched = querySnapshot.docs[0].data();
    }
    return(userFetched);
}

async function getNotes(id, type) {
    const field = (type == "Recipe") ? "recipeId" : "userId";
    const q = query(collection(db,"notes"),where(field,"==",id));
    const querySnapshot = await(getDocs(q));
    querySnapshot.forEach(snap => console.log("snap data is",snap.data()));
    let noteList = querySnapshot.docs.map(doc => doc.data());
    return(noteList);
}



export {mealCategory,mealProperties,users,allOption, findUserByName,getNotes,getUsersToSelectFrom,debounce};